<template>
  <v-dialog
      v-model="show"
      persistent
      width="360"
  >
    <Loader :show="loading" :size="24" :absolute="false" />

    <v-card outlined>
      <v-card-title class="text-h6 px-7 py-6 grey lighten-4 ">
        <div class="position-relative width-100 ">
          <span class="d-block pr-6">{{title || ''}}</span>
          <v-icon @click="handleCloseClicked" small class="cursor-pointer absolute-right absolute-vertical-center position-absolute">mdi-close</v-icon>
        </div>
      </v-card-title>
      <div class="px-7 py-6">

        <slot>

        </slot>

        <v-row class="mt-4">
          <v-col>
            <v-btn
                color="cyan accent-4"
                depressed outlined
                @click="handleCloseClicked"
                class="custom--btn"
                height="40"
                block
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
                color="cyan accent-4"
                depressed
                dark
                class="custom--btn"
                height="40"
                block
                @click="handleConfirmClicked"
            >
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </div>

    </v-card>
  </v-dialog>
</template>

<script>
import Loader from './Loader';

export default {
  name: 'ConfirmModal',

  computed: {

  },

  components: { Loader },

  props: [
    'show', 'title', 'loading'
  ],

  watch: {

  },

  created() {

  },

  mounted() {

  },

  methods: {
    handleConfirmClicked() {
      this.$emit('onConfirm')
    },
    handleCloseClicked() {
      this.$emit('onClose')
    }
  },

  data: () => ({

  }),
}
</script>

<style lang="scss">

</style>
