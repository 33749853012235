<template>
  <v-container fluid class="px-8">
    <v-row align="center">
      <v-col>
        <v-row align="center">
          <v-col cols="auto">
            <h3>Statistics</h3>
          </v-col>
          <v-col v-if="campaign" class="font-size-14">
            <template v-if="campaign.name">
              <span class="">{{campaign.name}}</span>
              <span class="mx-1">|</span>
            </template>
            <span class="grey--text" v-if="campaign.asin">{{campaign.asin}}</span>
          </v-col>
        </v-row>

      </v-col>

      <v-col cols="auto">
        <v-btn class="custom--btn" exact color="white" light depressed small to="/rotator">
          <v-icon x-small left>
            mdi-arrow-left
          </v-icon>
          Back To Rotator
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-card-actions class="pa-5">
            <v-row class="custom--gutters">
              <v-col>
                <v-text-field
                    v-model.trim="filter.search"
                    label="Search..."
                    solo
                    outlined
                    dense
                    flat
                    hide-details
                    class="font-size-15"
                    @change="handleSearch"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="auto" >
                <v-btn block depressed class="custom--btn" height="40" color="cyan accent-4" dark @click="statisticsChart.showModal = true">Show Graphic</v-btn>
              </v-col>
              <v-col cols="12" sm="auto">
                <v-btn block depressed outlined class="custom--btn" height="40" color="cyan accent-4" @click="resetStatistics.showModal = true">Reset Statistics</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>


        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
            :headers="tableHeaders"
            :items="redirections"
            :items-per-page="redirectionsTable.itemsPerPage"
            :server-items-length="pagination.totalItems"
            class="table--sm show-sort-icons-always elevation-0 custom--border fill-space-first-cell table--row-70"
            :loading="loading"
            loading-text="Loading... Please wait"
            :options.sync="options"
            @page-count="redirectionsTable.pageCount = $event"
            :page.sync="redirectionsTable.page"
            hide-default-footer
        >

          <template v-slot:item.keyword="{ item }">
            <div style="max-width: 400px;" class="text-truncate">{{item.keyword || emptyVal}}</div>
          </template>

          <template v-slot:item.location="{ item }">
            <div style="max-width: 200px;" class="text-truncate">{{item.location || emptyVal}}</div>
          </template>

          <template v-slot:item.referer="{ item }">
            <template v-if="isValidURL(item.referer)">
              <div class="d-flex align-center">
                <v-icon small class="mr-1">
                  mdi-link-variant
                </v-icon>
                <a :href="item.referer" style="max-width: 200px;" class="d-inline-block text-decoration-none text-truncate" target="_blank">{{item.referer}}</a>
              </div>
            </template>
            <template v-else>
              <div style="max-width: 200px;" class="text-truncate">{{item.referer || emptyVal}}</div>
            </template>
          </template>

          <template v-slot:item.link="{ item }">
            <v-row align="center" justify="center" dense class="flex-nowrap">
              <v-col cols="auto">
                <v-btn class="custom--btn" color="cyan accent-4" outlined depressed small @click="copyTextToClipboard(item.url)">Copy the link</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn class="custom--btn" @click="openLink({ src: item.url, isBlank: true })" color="cyan accent-4" outlined depressed small>Go to the link</v-btn>
              </v-col>
            </v-row>
          </template>

        </v-data-table>

        <v-row class="mt-3 mb-0" align="center" v-show="redirectionsTable.pageCount > 1">
          <v-col cols="12">
            <v-pagination
                v-model="redirectionsTable.page"
                :length="redirectionsTable.pageCount"
            ></v-pagination>
          </v-col>
        </v-row>

        <v-dialog
            v-model="statisticsChart.showModal"
            width="1085"
        >
          <v-card outlined>
            <v-card-title class="text-h6 px-7 py-6 grey lighten-4 ">
              <div class="position-relative width-100 ">
                <span class="d-block pr-6">Statistics Graph</span>
                <v-icon @click="statisticsChart.showModal = false" small class="cursor-pointer absolute-right absolute-vertical-center position-absolute">mdi-close</v-icon>
              </div>
            </v-card-title>

            <div class="py-6 px-7">
              <div class="position-relative">
                <Loader :show="statisticsChart.loading" :size="36" :absolute="true" />

                <v-btn-toggle
                    v-model="statisticsChart.chartType"
                    color="cyan accent-4"
                    dense
                    class="d-flex justify-center btn-toggle--active-outline"
                >
                  <v-btn class="custom--btn" value="day" outlined small>
                    Day
                  </v-btn>
                  <v-btn class="custom--btn" value="week" outlined small>
                    Week
                  </v-btn>
                  <v-btn class="custom--btn" value="month" outlined small>
                    Month
                  </v-btn>
                </v-btn-toggle>

                <div key="chart-wrap">
                  <apexchart height="280" type="bar" :key="statisticsChart.updateCounter" :options="statisticsChart.options" :series="statisticsChart.series"></apexchart>
                </div>

                <div class="mt-4 position-relative">
                  <p class="text-h6">Redirections By Platform</p>
                  <div key="chart-wrap">
                    <apexchart height="280" type="bar" :key="redirectionsByDeviceChart.updateCounter" :options="redirectionsByDeviceChart.options" :series="redirectionsByDeviceChart.series"></apexchart>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-dialog>

        <ConfirmModal
          title="Reset Statistics"
          :show="resetStatistics.showModal"
          @onConfirm="handleResetStatistics"
          @onClose="resetStatistics.showModal = false"
          :loading="loading"
        >
          <v-card-text class="pa-0">
            <p class="mb-1">Reset Campaign Statistics?</p>
            <template v-if="campaign">
              <p class="mb-0">
                <span class="text--primary font-weight-medium">Name:</span> {{campaign.name}}
              </p>
              <p class="mb-0">
                <span class="text--primary font-weight-medium">Type:</span> {{campaign.type}}
              </p>
              <p class="mb-0" v-if="campaign.asin">
                <span class="text--primary font-weight-medium">ASIN:</span> {{campaign.asin}}
              </p>
            </template>
          </v-card-text>
        </ConfirmModal>

      </v-col>
    </v-row>

  </v-container>
</template>

<script>

import {mapGetters, mapMutations, mapState} from 'vuex';
import rotatorApi from '../api/rotator';
import { showNotification, validURL, copyTextToClipboard, openLink, capitalize } from '../js/helpers';
import { mapCampaign } from '../js/rotator';
import moment from 'moment-timezone';
import { LA_TIMEZONE, DATE_FORMATS } from '../helpers/contants';
import Loader from '../components/Loader';
import ConfirmModal from '../components/ConfirmModal';

const DEFAULT_ITEMS_PER_PAGE = 8;

export default {
  name: 'RotatorStatistics',

  computed: {
    ...mapState(['user']),
    ...mapState('rotator', [
      'keywordDataByCampaign', 'campaignsStatistics'
    ]),
    ...mapGetters('rotator', {
      rotatorTypes: 'getAvailableRotatorTypes'
    }),
    settings() {
      if (!this.rotatorTypes || !this.campaign) { return {} }
      return this.rotatorTypes[this.campaign.type] || {};
    },
    tableHeaders() {
      const { showKeywordsTable } = this.settings;

      const headers = [];

      if (showKeywordsTable) {
        headers.push({ text: 'Keyword', value: 'keyword', sortable: false });
      }

      headers.push(...[
        { text: 'Location', value: 'location', width: 180, sortable: false },
        { text: 'Referer', value: 'referer', width: 150, sortable: false },
        { text: 'Device Type', value: 'deviceType', width: 150, sortable: false },
        { text: 'Device Platform', value: 'devicePlatform', width: 180, sortable: false },
      ]);

      if (this.campaign?.forceAmazonApp) {
        headers.push({ text: 'Opened in', value: 'openedIn', width: 100, sortable: false });
      }

      headers.push({ text: 'Date', value: 'dateFormatted', width: 180, sortable: false });
      headers.push({ text: 'Link', value: 'link', width: 243, sortable: false });

      return headers;
    },
  },

  components: {
    ConfirmModal,
    Loader
  },

  watch: {
    'options.page': {
      handler (val, newVal) {
        if (val === newVal) { return }
        this.getRedirectionsByPage();
      },
      deep: true,
    },
    'options.isReset': {
      handler (val, newVal) {
        if (val === newVal) { return }
        this.clearStatisticsData();
      },
      deep: true,
    },
    'options.search': {
      handler (val, newVal) {
        if (val === newVal) { return }
        this.getRedirectionsByPage();
      },
      deep: true,
    },
    'statisticsChart.showModal': {
      handler: function(show) {
        if (!show) {
          return this.clearChartData();
        }

        this.$nextTick(function() {
          this.renderCharts();
        });
      }
    },
    'statisticsChart.chartType': {
      handler: function() {
        this.$nextTick(function() {
          this.renderCharts();
        });
      }
    }
  },

  methods: {
    ...mapMutations(['setLoading']),
    clearStatisticsData() {
      this.redirections = [];
      this.pagination.totalItems = 0;
    },
    clearChartData() {
      this.statisticsChart.options.xaxis.categories = [];
      this.statisticsChart.series[0].data = [];
      this.redirectionsByDeviceChart.options.xaxis.categories = [];
      this.redirectionsByDeviceChart.series = [];
    },
    async renderCharts() {
      if (!this.statisticsChart.showModal || !this.campaign?._id) { return }

      const { forceAmazonApp } = this.campaign;

      this.statisticsChart.loading = true;

      const res = await rotatorApi.loadStatisticsChartByPeriod({
        campaignId: this.campaign._id,
        chartType: this.statisticsChart.chartType
      });

      this.statisticsChart.loading = false;

      if (!res.status || (!res.redirectionAmounts && !res.redirectionAmountsByDevice)) {
        return;
      }

      const redirectionAmounts = res.redirectionAmounts || new Array(7).fill(0);
      const redirectionAmountsByDevice = res.redirectionAmountsByDevice || new Array(7).fill({});

      const momentEnd = moment().tz(LA_TIMEZONE).format(DATE_FORMATS.yearMonthDayWithDash);
      let momentStart = moment().tz(LA_TIMEZONE).subtract(6, this.statisticsChart.chartType).format(DATE_FORMATS.yearMonthDayWithDash);
      let i = 0;

      const allDevices = new Set([]);
      redirectionAmountsByDevice.forEach(devicesData => {
        Object.keys(devicesData).forEach(device => allDevices.add(device));
      });

      const days = [], availableRedirectDestination = ['Web'];

      if (forceAmazonApp) {
        availableRedirectDestination.push(...['App', 'Unknown']);
      }

      const redirectionsStatisticsChartData = [];
      let redirectionsByDeviceChartSeries = [];

      allDevices.forEach(device => {
        availableRedirectDestination.forEach(method => {
          if (device === 'Desktop' && method !== 'Web') { return }

          let name = device;
          if (forceAmazonApp && device !== 'Desktop') {
            name += ` ${method}`;
          }

          redirectionsByDeviceChartSeries.push({
            name, method, type: 'column', group: device, data: []
          });
        });
      });

      while (momentStart <= momentEnd) {
        const redirectionAmount = redirectionAmounts[i];
        const redirectionByDeviceObj = redirectionAmountsByDevice[i] || {};

        let weekIndex = Math.ceil(moment(momentStart).date() / 7), formattedDate;

        switch (this.statisticsChart.chartType) {
          case "day":
            formattedDate = moment(momentStart).format(DATE_FORMATS.yearMonthDaySpaces);
            break;
          case "week":
            formattedDate = moment(momentStart).format(DATE_FORMATS.yearMonth) + ` W${weekIndex}`;
            break;
          case "month":
            formattedDate = moment(momentStart).format(DATE_FORMATS.yearMonth) ;
            break;

          default:
            formattedDate = moment(momentStart).format(DATE_FORMATS.yearMonth) ;
            break;
        }

        days.push(formattedDate);
        redirectionsStatisticsChartData.push(redirectionAmount);

        redirectionsByDeviceChartSeries.forEach(series => {
          let valueByDevice = 0;

          if (this.campaign.forceAmazonApp) {
            valueByDevice = redirectionByDeviceObj[series.group]?.[series.method] || 0;
          } else {
            valueByDevice = Object.values(redirectionByDeviceObj[series.group] || []).reduce((acc, val) => acc += val, 0);
          }

          series.data.push(valueByDevice || 0);
        });

        momentStart = moment(momentStart).add(1, this.statisticsChart.chartType).format(DATE_FORMATS.yearMonthDayWithDash);
        i++;
      }

      redirectionsByDeviceChartSeries = redirectionsByDeviceChartSeries.filter(series => {
        return series.data.every(val => val === 0) ? false : true;
      });

      this.statisticsChart.options.xaxis.categories = days;
      this.statisticsChart.series[0].data = redirectionsStatisticsChartData;

      this.redirectionsByDeviceChart.options.xaxis.categories = days;
      this.redirectionsByDeviceChart.series = redirectionsByDeviceChartSeries;

      this.$nextTick(function() {
        this.statisticsChart.updateCounter++;
        this.redirectionsByDeviceChart.updateCounter++;
      });
    },
    handleSearch(e) {
      this.$set(this.options, 'search', this.filter.search);
      this.options.page = 1;
    },
    async handleResetStatistics() {
      if (!this.campaign._id) { return }
      try {
        this.loading = true;

        const res = await rotatorApi.resetStatistics(this.campaign._id);
        this.loading = false;

        if (!res || !res.status) {
          return;
        }

        this.$set(this.options, 'isReset', true);
        this.resetStatistics.showModal = false;

        this.$nextTick(function() {
          showNotification({ title: 'Updated!' });
        });
      } catch (err) {
        console.log(err);
        this.loading = false;
        showNotification({ type: 'error' });
      }
    },
    async getRedirectionsByPage() {
      if (!this.campaign || !this.campaign._id) { return }

      const { page = 1, itemsPerPage = DEFAULT_ITEMS_PER_PAGE } = this.options;

      this.redirections = [];
      this.loading = true;

      const redirections = await rotatorApi.loadRedirectionsByPage({
        campaignId: this.campaign._id,
        results: itemsPerPage,
        page,
        search: this.filter.search
      });

      if (!redirections.status || !redirections.hasOwnProperty('redirectionsAmount') || !redirections.hasOwnProperty('redirectionTableValues')) {
        this.loading = false;
        return;
      }

      this.redirections = redirections.redirectionTableValues.map(this.mapRedirection);
      this.pagination.totalItems = redirections.redirectionsAmount;

      this.$nextTick(function() {
        this.loading = false;
      });
    },
    mapRedirection(redirection) {
      let { referer, location, date, isAppOpened = false, deviceType, devicePlatform } = redirection;

      let dateFormatted = '-';
      if (date) {
        dateFormatted = moment(date).format(DATE_FORMATS.fullDateWithTime);
      }

      if (!referer || referer === 'none') { referer = this.emptyVal }
      if (!location || location === 'unknown') { location = this.emptyVal }
      if (!devicePlatform || devicePlatform === 'unknown') { devicePlatform = this.emptyVal }

      deviceType = deviceType ? capitalize(deviceType) : this.emptyVal;

      return {
        ...redirection, referer, location, dateFormatted, openedIn: isAppOpened ? 'App' : 'Browser',
        deviceType, devicePlatform
      };
    }
  },

  async beforeMount() {
    this.copyTextToClipboard = copyTextToClipboard;

    const campaignId = this.$route.params.id;
    if (!campaignId) { return this.$router.push('/rotator') }

    this.setLoading(true);

    const response = await rotatorApi.getCampaignData(campaignId);
    if (!response || !response.status || !response.campaign) { return this.$router.push('/rotator') }

    this.campaign = {
      ...mapCampaign(response.campaign, this.keywordDataByCampaign, this.campaignsStatistics)
    };

    this.$nextTick(function() {
      this.getRedirectionsByPage();
      this.setLoading(false);
    });
  },

  created() {
    this.isValidURL = validURL;
    this.openLink = openLink;
  },

  mounted() {

  },

  data: () => ({
    redirectionsByDeviceChart: {
      options: {
        colors:['rgba(0,143,251,0.85)', 'rgba(41,195,190,0.85)', 'rgba(255,69,96,0.85)'],
        grid: {
          row: {
            colors: ['#fff', '#f2f2f2']
          }
        },
        stroke: {
          width: 2
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false
          }
        },
        chart: {
          id: 'redirections-chart',
          width: '100%',
          stacked: true,
          animations: {
            speed: 500
          },
        },
        noData: {
          text: 'Loading...'
        },
        xaxis: {
          categories: []
        },
        legend: {
          show: true, showForSingleSeries: true
        },
        yaxis: [
          {
            labels: {
              formatter: function(val) {
                return val.toFixed(0);
              }
            }
          }
        ]
      },
      series: [],
      updateCounter: 1,
    },
    emptyVal: '--',
    resetStatistics: {
      showModal: false
    },
    statisticsChart: {
      options: {
        grid: {
          row: {
            colors: ['#fff', '#f2f2f2']
          }
        },
        stroke: {
          width: 2
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
          }
        },
        chart: {
          id: 'statistics-chart',
          animations: {
            speed: 500
          },
        },
        noData: {
          text: 'Loading...'
        },
        xaxis: {
          categories: [],
        }
      },
      series: [{
        name: 'Redirections amount',
        data: []
      }],
      showModal: false,
      chartType: 'day',
      updateCounter: 1,
      loading: false
    },
    pagination: { totalItems: 1 },
    campaign: null,
    redirections: [],
    options: {},
    filter: { search: '' },
    redirectionsTable: {
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
      page: 1,
      pageCount: 0
    },
    loading: true
  }),
}
</script>

<style lang="scss">

</style>